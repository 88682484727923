<template>
  <div>
    <h4>
      {{ $t('ACADEMICO.CAMPOS_PARA_INCLUIR_NO_RELATORIO') }}
    </h4>

    <b-row class="mb-3">
      <b-col cols="12">
        <h6>{{ $t('ACADEMICO.CONFIG_RELATORIO') }}</h6>
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.horizontal"
          type="boolean"
          :label="$t('ACADEMICO.ORIENTACAO_RELATORIO')"
          :placeholder="$t('ACADEMICO.ORIENTACAO_RELATORIO')"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12">
        <h6>{{ $t('ACADEMICO.DADOS_ALUNO') }}</h6>
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.cpf"
          type="boolean"
          :label="$t('ACADEMICO.CPF')"
          :placeholder="$t('ACADEMICO.CPF')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.identidade"
          type="boolean"
          :label="$t('ACADEMICO.IDENTIDADE')"
          :placeholder="$t('ACADEMICO.IDENTIDADE')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.orgaoExpedidor"
          type="boolean"
          :label="$t('ACADEMICO.ORGAO_EXPEDIDOR')"
          :placeholder="$t('ACADEMICO.ORGAO_EXPEDIDOR')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.dataExpedicao"
          type="boolean"
          :label="$t('ACADEMICO.DATA_EXPEDICAO')"
          :placeholder="$t('ACADEMICO.DATA_EXPEDICAO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.dataMatricula"
          type="boolean"
          :label="$t('ACADEMICO.DATA_MATRICULA')"
          :placeholder="$t('ACADEMICO.DATA_MATRICULA')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.dataNascimento"
          type="boolean"
          :label="$t('ACADEMICO.DATA_NASCIMENTO')"
          :placeholder="$t('ACADEMICO.DATA_NASCIMENTO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.estadoCivil"
          type="boolean"
          :label="$t('ACADEMICO.ESTADO_CIVIL')"
          :placeholder="$t('ACADEMICO.ESTADO_CIVIL')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.sexo"
          type="boolean"
          :label="$t('ACADEMICO.SEXO')"
          :placeholder="$t('ACADEMICO.SEXO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.religiao"
          type="boolean"
          :label="$t('ACADEMICO.RELIGIAO')"
          :placeholder="$t('ACADEMICO.RELIGIAO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.naturalidade"
          type="boolean"
          :label="$t('ACADEMICO.NATURALIDADE')"
          :placeholder="$t('ACADEMICO.NATURALIDADE')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.nacionalidade"
          type="boolean"
          :label="$t('ACADEMICO.NACIONALIDADE')"
          :placeholder="$t('ACADEMICO.NACIONALIDADE')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.registroNascimento"
          type="boolean"
          :label="$t('ACADEMICO.REGISTRO_NASCIMENTO')"
          :placeholder="$t('ACADEMICO.REGISTRO_NASCIMENTO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.instituicaoOrigem"
          type="boolean"
          :label="$t('ACADEMICO.INSTITUICAO_ORIGEM')"
          :placeholder="$t('ACADEMICO.INSTITUICAO_ORIGEM')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.foto"
          type="boolean"
          :label="$t('ACADEMICO.FOTO')"
          :placeholder="$t('ACADEMICO.FOTO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.turma"
          type="boolean"
          :label="$t('ACADEMICO.TURMA')"
          :placeholder="$t('ACADEMICO.TURMA')"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12">
        <h6>{{ $t('ACADEMICO.DADOS_ENDERECO') }}</h6>
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.cep"
          type="boolean"
          :label="$t('ACADEMICO.CEP')"
          :placeholder="$t('ACADEMICO.CEP')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.estado"
          type="boolean"
          :label="$t('ACADEMICO.ESTADO')"
          :placeholder="$t('ACADEMICO.ESTADO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.cidade"
          type="boolean"
          :label="$t('ACADEMICO.CIDADE')"
          :placeholder="$t('ACADEMICO.CIDADE')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.bairro"
          type="boolean"
          :label="$t('ACADEMICO.BAIRRO')"
          :placeholder="$t('ACADEMICO.BAIRRO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.endereco"
          type="boolean"
          :label="$t('ACADEMICO.ENDERECO')"
          :placeholder="$t('ACADEMICO.ENDERECO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.numero"
          type="boolean"
          :label="$t('ACADEMICO.NUMERO')"
          :placeholder="$t('ACADEMICO.NUMERO')"
        />
      </b-col>
       <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.complemento"
          type="boolean"
          :label="$t('ACADEMICO.COMPLEMENTO')"
          :placeholder="$t('ACADEMICO.COMPLEMENTO')"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12">
        <h6>{{ $t('ACADEMICO.DADOS_CONTATO') }}</h6>
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.residencial"
          type="boolean"
          :label="$t('ACADEMICO.RESIDENCIAL')"
          :placeholder="$t('ACADEMICO.RESIDENCIAL')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.celular"
          type="boolean"
          :label="$t('ACADEMICO.CELULAR')"
          :placeholder="$t('ACADEMICO.CELULAR')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.email"
          type="boolean"
          :label="$t('ACADEMICO.EMAIL')"
          :placeholder="$t('ACADEMICO.EMAIL')"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12">
        <h6>{{ $t('ACADEMICO.DADOS_RESPONSAVEIS') }}</h6>
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.responsavelFinanceiro"
          type="boolean"
          :label="$t('ACADEMICO.RESPONSAVEL_FINANCEIRO')"
          :placeholder="$t('ACADEMICO.RESPONSAVEL_FINANCEIRO')"
        />
      </b-col>
      <b-col cols="12" md="3" class="mb-2">
        <input-checkbox
          v-model="form.responsavelPedagogico"
          type="boolean"
          :label="$t('ACADEMICO.RESPONSAVEL_PEDAGOGICO')"
          :placeholder="$t('ACADEMICO.RESPONSAVEL_PEDAGOGICO')"
        />
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import { InputCheckbox } from '@/components/inputs';

export default {
  name: 'OpcoesDeCamposRelatorioTurmas',
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputCheckbox,
  },

};
</script>

<style scoped>
</style>