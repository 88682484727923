<template>
  <div>
    <h4>
      {{ $t('ACADEMICO.FILTROS') }}
    </h4>
    <b-row>
      <b-col cols="12" md="3">
        <input-select
          :disabled="desabilitar.form"
          ref="anoLetivoId"
          :options="opcoes.anosLetivos"
          v-model="form.anoLetivoId"
          :label="$t('GERAL.ANO_LETIVO')"
          valueField="id"
          textField="ano"
          required
        />
      </b-col>
      <b-col cols="12" md="3">
        <input-select-search
          :isDisabled="desabilitar.curso"
          ref="cursoId"
          :options="opcoes.cursos"
          v-model="form.cursoId"
          :label="$t('GERAL.CURSO')"
        />
      </b-col>
      <b-col cols="12" md="3">
        <input-select
          ref="grupoTurmaId"
          :options="opcoes.grupoTurmas"
          v-model="form.grupoTurmaId"
          :label="$t('GERAL.GRUPO_TURMA')"
          valueField="id"
          textField="descricao"
          :disabled="desabilitar.grupoTurma"
        />
      </b-col>
      <b-col cols="12" md="3">
        <input-select
          ref="turmaId"
          :options="opcoes.turmas"
          v-model="form.turmaId"
          :label="$t('GERAL.TURMA')"
          valueField="id"
          textField="descricao"
          :disabled="desabilitar.turma"
        />
      </b-col>
      <b-col cols="12" md="auto">
        <b-btn class="w-100" variant="secondary" @click="limpar">{{
          $t('GERAL.LIMPAR')
        }}</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Aux & helpes
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

//servives
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';

// Components:
import { InputSelect, InputSelectSearch } from '@/components/inputs';

export default {
  name: 'FiltroContratosPorTurma',

  props: {
    form: { type: Object, default: Object },
  },

  components: {
    InputSelect,
    InputSelectSearch,
  },
  data() {
    return {
      desabilitar: {
        form: false,
        curso: true,
        grupoTurma: true,
        turma: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        grupoTurmas: [],
        turmas: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
    this.refs();
  },
  watch: {
    'form.anoLetivoId': {
      handler(anoLetivoId) {
        if (anoLetivoId) {
          this.getCursos();
        } else {
          this.opcoes.cursos = [];
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
          this.desabilitar.curso = true;
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
        }
        this.form.cursoId = null;
        this.form.grupoTurmaId = null;
        this.form.turmaId = null;
      },
    },
    'form.cursoId': {
      handler(cursoId) {
        if (cursoId) {
          this.getGrupoTurma(cursoId);
        } else {
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
        }
        this.form.grupoTurmaId = null;
        this.form.turmaId = null;
      },
    },
    'form.grupoTurmaId': {
      handler(turmaId) {
        if (turmaId) {
          this.getTurmas();
        } else {
          this.desabilitar.turma = true;
          this.opcoes.turmas = [];
        }
        this.form.turmaId = null;
      },
    },
  },
  methods: {
    refs() {
      this.$emit('refs', this.$refs);
    },
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos() {
      if (this.form.anoLetivoId.length === 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(this.form.anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGrupoTurma() {
      if (this.form.anoLetivoId.length == 0 || this.form.cursoId.length == 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.form.cursoId,
        this.form.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;

          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurmas() {
      if (this.form.cursoId.length == 0 || this.form.grupoTurmaId.length == 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      TurmaService.obterTurmaPorGrupoTurma(this.form)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
          this.desabilitar.turma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limpar() {
      this.$emit('limpar');
    },
  },
};
</script>
