<template>
  <div>
    <titulo-relatorio-turmas />
    <filtro-relatorios-turma
      @refs="capturarRefs"
      :form="form"
      @limpar="limparFiltro"
    />
    <hr />
    <opcoes-de-campos-relatorios-turmas :form="form" />
    <div>
      <b-row>
        <b-col cols="12" md="4">
          <b-row class="justify-content-start">
            <b-col cols="12" md="auto" class="justify-self-start">
              <b-btn
                class="w-100 botao-acao-filtro"
                variant="secondary"
                @click="limparOpcoesDeCampos"
              >
                {{ $t('GERAL.LIMPAR') }}
              </b-btn>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="8">
          <b-row class="justify-content-end">
            <b-col cols="12" md="auto">
              <b-btn
                class="w-100 botao-acao-filtro my-3"
                variant="info"
                @click="baixarRelatorioTurmasPdf"
              >
                {{ $t('GERAL.GERAR_RELATORIO') }}
              </b-btn>
            </b-col>
            <b-col cols="12" md="auto">
              <b-btn
                class="w-100 botao-acao-filtro mb-3"
                variant="info"
                @click="baixarRelatorioFormatoExcel"
              >
                {{ $t('ACADEMICO.EXPORTAR_PARA_EXCEL') }}
              </b-btn>
            </b-col>
            <b-col cols="12" md="auto">
              <b-btn
                class="w-100 botao-acao-filtro"
                variant="info"
                @click="baixarEtiquetasPdf"
              >
                {{ $t('ACADEMICO.IMPRIMIR_ETIQUETAS') }}
              </b-btn>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// HELPS & AUX
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// SERVICES
import TurmaService from '@/common/services/academico/turma.service';

// COMPONENTS
import FiltroRelatoriosTurma from '@/views/academico/relatorios/relatorios-turmas/components/FiltroRelatoriosTurma';
import OpcoesDeCamposRelatoriosTurmas from '@/views/academico/relatorios/relatorios-turmas/components/OpcoesDeCamposRelatoriosTurmas';
import TituloRelatorioTurmas from '@/views/academico/relatorios/relatorios-turmas/components/TituloRelatorioTurmas';

export default {
  name: 'RelatoriosTurmas',
  components: {
    TituloRelatorioTurmas,
    FiltroRelatoriosTurma,
    OpcoesDeCamposRelatoriosTurmas,
  },

  data() {
    return {
      form: {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
        horizontal: false,
        cpf: false,
        identidade: false,
        orgaoExpedidor: false,
        dataExpedicao: false,
        dataMatricula: false,
        dataNascimento: false,
        estadoCivil: false,
        sexo: false,
        religiao: false,
        naturalidade: false,
        nacionalidade: false,
        registroNascimento: false,
        instituicaoOrigem: false,
        foto: false,
        cep: false,
        estado: false,
        cidade: false,
        bairro: false,
        endereco: false,
        numero: false,
        complemento: false,
        residencial: false,
        celular: false,
        email: false,
        responsavelFinanceiro: false,
        responsavelPedagogico: false,
        turma: false,
      },
    };
  },
  methods: {
    baixarRelatorioTurmasPdf() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      TurmaService.baixarRelatorioTurmas(this.form)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    baixarRelatorioFormatoExcel() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      TurmaService.baixarRelatorioExcel(this.form)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    baixarEtiquetasPdf() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      TurmaService.baixarRelatorioEtiqueta(this.form)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // filtro
    limparFiltro() {
      this.form = {
        ...this.form,
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
      };
    },
    limparOpcoesDeCampos() {
      this.form = {
        ...this.form,
        horizontal: false,
        cpf: false,
        identidade: false,
        orgaoExpedidor: false,
        dataExpedicao: false,
        dataMatricula: false,
        dataNascimento: false,
        estadoCivil: false,
        sexo: false,
        religiao: false,
        naturalidade: false,
        nacionalidade: false,
        registroNascimento: false,
        instituicaoOrigem: false,
        foto: false,
        cep: false,
        estado: false,
        cidade: false,
        bairro: false,
        endereco: false,
        numero: false,
        residencial: false,
        celular: false,
        email: false,
        responsavelFinanceiro: false,
        responsavelPedagogico: false,
        turma: false,
        complemento: false,
      };
    },

    capturarRefs(refs) {
      this.$refs = refs;
    },

    // validacao
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>

<style scoped>
.gap-2 {
  gap: 5px;
}
</style>
