import { render, staticRenderFns } from "./OpcoesDeCamposRelatoriosTurmas.vue?vue&type=template&id=8067dc18&scoped=true&"
import script from "./OpcoesDeCamposRelatoriosTurmas.vue?vue&type=script&lang=js&"
export * from "./OpcoesDeCamposRelatoriosTurmas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8067dc18",
  null
  
)

export default component.exports